<template>
    <ProviderNotificationCreateForm />
</template>

<script>
import ProviderNotificationCreateForm from '@/components/ProviderNotification/ProviderNotificationCreateForm'
export default {
    name: "CreateProviderNotification",
    components: {
        ProviderNotificationCreateForm
    },
    data: () => ({
        breadcrumbs: [
            { title: 'Главная', link: '/' },
            { title: 'Заказ ПО', link: '/' },
            { title: 'Перечень уведомлений поставщика', link: '/provider-notifications' },
            { title: 'Создание уведомления поставщика' }
        ]
    })
}
</script>
